import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Upload, X } from 'lucide-react';

const ScreenshotUploader = forwardRef(({ onFileSelect, t, className = '', classNameChild = '' }, ref) => {
  const fileInputRef = useRef(null);
  const [screenshotPreview, setScreenshotPreview] = useState(null);
  const [screenshotName, setScreenshotName] = useState('');

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 2 * 1024 * 1024) {
      alert('File size exceeds 2 MB');
      e.target.value = null;
      setScreenshotPreview(null);
      setScreenshotName('');
      onFileSelect(null); // Pass null to parent
    } else if (file) {
      setScreenshotName(file.name);
      const previewUrl = URL.createObjectURL(file);
      setScreenshotPreview(previewUrl);
      onFileSelect(file); // Pass the file to parent
    }
  };

  const handleRemoveScreenshot = () => {
    setScreenshotPreview(null);
    setScreenshotName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    onFileSelect(null);
  };

  // Expose handleRemoveScreenshot to the parent component
  useImperativeHandle(ref, () => ({
    removeScreenshot: handleRemoveScreenshot,
  }));

  return (
    <div className={`${className} font-manrope`}>
      <div
        className={`border-2 bg-navBarLightPurple border-dashed border-gray-300 rounded-lg p-4 text-center relative ${classNameChild}`}
      >
        {screenshotPreview ? (
          <div className="flex flex-col items-center relative">
            <img
              src={screenshotPreview}
              alt="Preview"
              className="h-18 w-32 border-2 border-logoLightPurple p-1 object-cover mb-2 rounded-md"
            />
            <button
              type="button"
              onClick={handleRemoveScreenshot}
              className="absolute bottom-20 left-52 lg:ml-12 ml-2 border-2 border-logoLightPurple rounded-full p-1 bg-gray-200 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <X size={16} />
            </button>
            <p className="text-sm text-gray-700 mt-2">{screenshotName}</p>
          </div>
        ) : (
          <>
            <Upload className="mx-auto text-gray-400" size={24} />
            <p className="mt-2 text-sm text-gray-500">{t('Drop your image here')}</p>
            <p className="text-xs text-gray-400">{t('or')}</p>
          </>
        )}
        <input
          type="file"
          id="screenshot"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
        />
        <button
          type="button"
          onClick={handleFileButtonClick}
          className="mt-2 px-3 py-2 w-2/3 text-activeButtonPurple border-2 border-activeButtonPurple rounded-lg focus:outline-none"
        >
          {t('Choose File')}
        </button>
      </div>
    </div>
  );
});

export default ScreenshotUploader;
