import React, { useEffect } from 'react';

const PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY;
const BUY_BUTTON_ID = process.env.STRIPE_BUY_BUTTON_ID;
console.log("Button Id", BUY_BUTTON_ID);


const StripeButton = () => {
  useEffect(() => {
    const BUY_BUTTON_ID = process.env.STRIPE_BUY_BUTTON_ID;
    console.log("Button Id 2", BUY_BUTTON_ID);
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;

    script.onload = () => {
      const handleMessage = (event) => {
        try {
          if (event.data && typeof event.data.payload !== 'undefined') {
          } else {
            return;
          }
        } catch (error) {
          console.error('Error handling Stripe event:', error);
        }
      };

      window.addEventListener('message', handleMessage);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="lg:scale-125 lg:p-8">
      <stripe-buy-button
        buy-button-id={BUY_BUTTON_ID}
        publishable-key={PUBLISHABLE_KEY}
      />
    </div>
  );
};

export default StripeButton;