import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";

if (process.env.GA_ID) {
  ReactGA.initialize(process.env.GA_ID);
} else {
  console.log("No GA ID provided");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const sendToAnalytics = (metric) => {
  const { id, name, value } = metric;
  ReactGA.gtag("event", name, {
    event_category: "Web Vitals",
    event_label: id,
    value: Math.round(name === "CLS" ? value * 1000 : value), // Convert CLS to an integer
    non_interaction: true,
  });
};

reportWebVitals(sendToAnalytics);
