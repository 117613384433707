import React, { useState } from "react";
import ColorPicker from '../ColorPicker/ColorPicker';
import ScreenshotUploader from '../../../common/ScreenshotUploader/ScreenshotUploader'; 
import { useTranslation } from "react-i18next";

function DesignPanel({
  mainColor,
  setMainColor,
  bgColor,
  setBgColor,
  logoFile,
  setLogoFile,
  selectedOverallStyle,
  setSelectedOverallStyle,
  selectedEyeFrameStyle,
  setSelectedEyeFrameStyle,
  selectedEyeBallStyle,
  setSelectedEyeBallStyle,
}) {
  const { t } = useTranslation();

  const [activePicker, setActivePicker] = useState(null);

  const handleButtonClick = (picker) => {
    setActivePicker(picker);
  };

  const handleColorChange = (newColor) => {
    if (activePicker === 'main') {
      setMainColor(newColor); 
    } else if (activePicker === 'background') {
      setBgColor(newColor); 
    }
    setActivePicker(null); 
  };

  const getIconColor = (icon, selectedIcon) => {
    return icon === selectedIcon ? "#958AF0" : "#A5A6B5"; 
  };

  return (
    <div className="bg-white w-full h-auto p-3 col-span-2 row-span-3 mb-auto rounded-lg items-start grid box-border">
      <div className="flex flex-col items-start w-auto p-3 gap-y-10">
        <div className="items-start flex font-manrope text-boldTextGray font-semibold">
          {t('Choose your design')}
        </div>
        {/* Colors Section */}
        <div className="w-full">
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Colors')}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" alt="color palette icon" className="h-5 w-auto pr-2">
              <path d="M16.856 10.856L17.3142 11.3142C17.4535 11.4535 17.564 11.6188 17.6394 11.8008C17.7148 11.9828 17.7536 12.1779 17.7536 12.3748C17.7536 12.5718 17.7148 12.7669 17.6394 12.9489C17.564 13.1309 17.4535 13.2962 17.3142 13.4355L16.6552 14.0945C16.5146 14.2352 16.3238 14.3142 16.1249 14.3142C15.926 14.3142 15.7352 14.2352 15.5946 14.0945L9.90522 8.40518C9.76456 8.26453 9.68555 8.07376 9.68555 7.87485C9.68555 7.67594 9.76456 7.48517 9.90522 7.34452L10.5642 6.68551C10.7035 6.54622 10.8689 6.43573 11.0509 6.36035C11.2328 6.28497 11.4279 6.24617 11.6249 6.24617C11.8219 6.24617 12.0169 6.28497 12.1989 6.36035C12.3809 6.43573 12.5463 6.54622 12.6855 6.68551L13.1437 7.14369L15.7301 4.55731C16.7415 3.54591 18.3894 3.48395 19.4282 4.46725C19.6832 4.70832 19.8873 4.99809 20.0284 5.3194C20.1695 5.64072 20.2447 5.98706 20.2496 6.33796C20.2546 6.68885 20.1891 7.03717 20.0571 7.36233C19.9251 7.68748 19.7292 7.98286 19.481 8.23101L16.856 10.856Z" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.8715 13.3716L9.62154 18.6216C9.24927 18.9941 8.78544 19.2621 8.27671 19.3985C7.76798 19.5349 7.23231 19.5349 6.72359 19.3985L4.52962 20.3563C4.39146 20.4166 4.23833 20.4339 4.0902 20.4058C3.94207 20.3778 3.80582 20.3058 3.69922 20.1992V20.1992C3.62269 20.1227 3.57099 20.0249 3.55088 19.9185C3.53077 19.8122 3.54318 19.7022 3.58648 19.603L4.60198 17.2769C4.46558 16.7682 4.46558 16.2325 4.60196 15.7237C4.73835 15.215 5.00631 14.7512 5.3789 14.3789L10.6289 9.12891" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="flex w-auto lg:items-center items-start justify-start flex-col lg:flex-row pt-3">
            {/* Main Color Picker */}
            <div className="flex items-center border border-2 border-gray-300 rounded-xl w-auto p-2">
              <div className="font-manrope font-semibold text-boldTextGray">
                {t('Main')}
                <div className="font-manrope text-navButtonsGray">{mainColor}</div>
              </div>
              <button
                className="mx-3 p-4 rounded border border-2 border-gray-300"
                style={{ backgroundColor: mainColor, boxShadow: '0 0 0 2px #E3E4FC inset' }}
                onClick={() => handleButtonClick('main')}
                aria-label="Select main color"
              ></button>
            </div>
            {/* Background Color Picker */}
            <div className="flex items-center w-auto border border-2 max-w-38 border-gray-300 rounded-xl lg:ml-3 lg:mt-0 mt-3 p-2">
              <div className="font-manrope font-semibold text-boldTextGray">
                {t('Background')}
                <div className="font-manrope text-navButtonsGray">{bgColor}</div>
              </div>
              <button
                className="mx-3 p-4 rounded border border-2 border-gray-300"
                style={{ backgroundColor: bgColor, boxShadow: '0 0 0 2px #E3E4FC inset' }}
                onClick={() => handleButtonClick('background')}
                aria-label="Select background color"
              ></button>
            </div>
            {/* Color Picker Component */}
            <div className="flex w-auto ml-8">
              {activePicker && <ColorPicker onColorChange={handleColorChange} />}
            </div>
          </div>
        </div>
        {/* Overall Style Section */}
        <div>
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Overall Style')}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  alt="swatch icon" className="h-5 w-auto pr-2" >
              <path d="M3.4377 16.2234L5.70525 3.36342C5.73979 3.16753 5.85074 2.99338 6.01368 2.87929C6.17662 2.7652 6.3782 2.72051 6.57409 2.75505L11.7443 3.6667C11.9402 3.70124 12.1144 3.81219 12.2285 3.97513C12.3426 4.13807 12.3872 4.33965 12.3527 4.53554L10.0736 17.4611C9.92011 18.3314 9.43144 19.1068 8.71253 19.6207C7.99361 20.1346 7.10181 20.3461 6.2287 20.2096C4.35907 19.9167 3.10908 18.0871 3.4377 16.2234Z" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.0888 11.7043L17.9291 9.21466C18.116 9.14663 18.3223 9.15564 18.5026 9.2397C18.6829 9.32377 18.8224 9.476 18.8904 9.66292L20.686 14.5963C20.7197 14.6889 20.7348 14.7871 20.7305 14.8855C20.7262 14.9839 20.7026 15.0805 20.661 15.1698C20.6194 15.259 20.5606 15.3392 20.4879 15.4058C20.4153 15.4723 20.3303 15.5239 20.2378 15.5576L7.9043 20.0466" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.625 15.2373V19.4998C20.625 19.6987 20.546 19.8895 20.4053 20.0302C20.2647 20.1708 20.0739 20.2498 19.875 20.2498H6.75" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.75 18C7.37132 18 7.875 17.4963 7.875 16.875C7.875 16.2537 7.37132 15.75 6.75 15.75C6.12868 15.75 5.625 16.2537 5.625 16.875C5.625 17.4963 6.12868 18 6.75 18Z" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="flex flex-row gap-3 pt-3">
            {/* Overall Style Option 1 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedOverallStyle("square")} style={{ cursor: "pointer" }}>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" fill="white" />
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("square", selectedOverallStyle)} strokeWidth="1.5" />
              <rect x="16" y="16" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="27" y="16" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="38" y="16" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="16" y="27" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="16" y="38" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
              <rect x="38" y="38" width="10" height="10" fill={getIconColor("square", selectedOverallStyle)} />
            </svg>
            {/* Overall Style Option 2 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedOverallStyle("dots")} style={{ cursor: "pointer" }}>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("dots", selectedOverallStyle)} strokeWidth="1.5" />
              <rect x="16" y="16" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="27" y="16" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="38" y="16" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="16" y="27" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="16" y="38" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
              <rect x="38" y="38" width="10" height="10" rx="5" fill={getIconColor("dots", selectedOverallStyle)} />
            </svg>
            {/* Add more overall style options if you have more */}
          </div>
        </div>
        {/* Eye Frame Style Section */}
        <div>
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Eye Frame Style')}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  alt="Eye frame icon" className="h-5 w-auto pr-2" >
              <path d="M5.99922 6H2.25" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 2.25V18.0001H21.75" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.0003 15.0001V6H8.99951" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 21.75V18" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <rect x="9" y="9" width="6" height="6" rx="1" fill="#D2D2E3"/>
            </svg>
          </div>
          <div className="flex flex-row gap-3 pt-3 flex-wrap">
            {/* Eye Frame Style Option 1 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeFrameStyle("square")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1392)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="20" y="20" width="55" height="55" stroke={getIconColor("square", selectedEyeFrameStyle)} strokeWidth="8" />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("square", selectedEyeFrameStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1392">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {/* Eye Frame Style Option 2 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeFrameStyle("extra-rounded")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1394)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="20" y="20" width="55" height="55" rx="12" stroke={getIconColor("extra-rounded", selectedEyeFrameStyle)} strokeWidth="8" strokeLinejoin="round" />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("extra-rounded", selectedEyeFrameStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1394">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="64" 
              height="64" 
              viewBox="0 0 64 64" 
              fill="none"
              onClick={() => setSelectedEyeFrameStyle("dot")} // Change this to the appropriate state handler
              style={{ cursor: "pointer" }} // Make the icon clickable
            >
              <g clipPath="url(#clip0_109_1396)">
                <path 
                  d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" 
                  fill="white"
                />
                <rect 
                  x="20" 
                  y="20" 
                  width="55" 
                  height="55" 
                  rx="27.5" 
                  stroke={getIconColor("dot", selectedEyeFrameStyle)} // Dynamic stroke color
                  strokeWidth="8" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                />
              </g>
              <path 
                d="M0.75 8C0.75 3.99594 3.99594 0.75 8 0.75H56C60.0041 0.75 63.25 3.99594 63.25 8V56C63.25 60.0041 60.0041 63.25 56 63.25H8C3.99594 63.25 0.75 60.0041 0.75 56V8Z" 
                stroke={getIconColor("dot", selectedEyeFrameStyle)} // Dynamic outer stroke color
                strokeWidth="1.5"
              />
              <defs>
                <clipPath id="clip0_109_1396">
                  <path d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            {/* Add other eye frame style options (dot, frame4, frame5) similarly */}
          </div>
        </div>
        {/* Eye Ball Style Section */}
        <div>
          <div className="font-manrope font-semibold text-boldTextGray items-center flex flex-row-reverse justify-end">
            {t('Eye Ball Style')}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  alt="Eye" className="h-5 w-auto pr-2" viewBox="0 0 24 24" fill="none">
              <path d="M5.99922 6H2.25" stroke="#D1D2E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 2.25V18.0001H21.75" stroke="#D1D2E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.0003 15.0001V6H8.99951" stroke="#D1D2E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 21.75V18" stroke="#D1D2E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <rect x="9" y="9" width="6" height="6" rx="1" fill="#958AF0"/>
            </svg>
          </div>
          <div className="flex flex-row gap-3 pt-3 flex-wrap">
            {/* Eye Ball Style Option 1 */}
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeBallStyle("square")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1413)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="16" y="16" width="63" height="63" fill={getIconColor("square", selectedEyeBallStyle)} />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("square", selectedEyeBallStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1413">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
             {/* Eye Ball Style Option 2 */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none"
              onClick={() => setSelectedEyeBallStyle("none")} style={{ cursor: "pointer" }}>
              <g clipPath="url(#clip0_109_1415)">
                <rect width="64" height="64" rx="8" fill="white" />
                <rect x="16" y="16" width="63" height="63" rx="16" fill={getIconColor("none", selectedEyeBallStyle)} />
              </g>
              <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="7.25" stroke={getIconColor("none", selectedEyeBallStyle)} strokeWidth="1.5" />
              <defs>
                <clipPath id="clip0_109_1415">
                  <rect width="64" height="64" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg> */}
                   {/* Eye Ball Style Option 3 */}
             <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="64" 
                height="64" 
                viewBox="0 0 64 64" 
                fill="none"
                onClick={() => setSelectedEyeBallStyle("dot")} // Change this to match your state handler
                style={{ cursor: "pointer" }} // Pointer to indicate it's clickable
              >
                <g clipPath="url(#clip0_109_1417)">
                  <path 
                    d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" 
                    fill="white" 
                  />
                  <rect 
                    x="16" 
                    y="16" 
                    width="63" 
                    height="63" 
                    rx="31.5" 
                    fill={getIconColor("dot", selectedEyeBallStyle)} // Dynamic fill color
                  />
                </g>
                <path 
                  d="M0.75 8C0.75 3.99594 3.99594 0.75 8 0.75H56C60.0041 0.75 63.25 3.99594 63.25 8V56C63.25 60.0041 60.0041 63.25 56 63.25H8C3.99594 63.25 0.75 60.0041 0.75 56V8Z" 
                  stroke={getIconColor("dot", selectedEyeBallStyle)} // Dynamic outer stroke color
                  strokeWidth="1.5" 
                />
                <defs>
                  <clipPath id="clip0_109_1417">
                    <path 
                      d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" 
                      fill="white" 
                    />
                  </clipPath>
                </defs>
              </svg>
            {/* Add other eye ball style options (dot, eye4, eye5) similarly */}
          </div>
        </div>
        {/* Logo Upload Section */}
        <section className="flex flex-col w-full">
          <div className="flex flex-row gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="flex">
              <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.25 14.9999L16.2803 11.0302C16.1397 10.8896 15.9489 10.8105 15.75 10.8105C15.5511 10.8105 15.3603 10.8896 15.2197 11.0302L11.0303 15.2196C10.8897 15.3602 10.6989 15.4392 10.5 15.4392C10.3011 15.4392 10.1103 15.3602 9.96967 15.2196L8.03033 13.2802C7.88967 13.1396 7.69891 13.0605 7.5 13.0605C7.30108 13.0605 7.11032 13.1396 6.96967 13.2802L3.75 16.4999" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.375 9.75C9.99632 9.75 10.5 9.24632 10.5 8.625C10.5 8.00368 9.99632 7.5 9.375 7.5C8.75368 7.5 8.25 8.00368 8.25 8.625C8.25 9.24632 8.75368 9.75 9.375 9.75Z" fill="#958AF0"/>
            </svg>
            <h2 className="text-boldTextGray font-semibold mb-4 font-manrope text-start lg:text-left">{t('Your logo')}</h2>
          </div> 
          <div className="flex flex-col lg:flex-row w-full gap-x-3">
            <ScreenshotUploader
              onFileSelect={setLogoFile}
              className="lg:w-2/3 w-full"
              classNameChild='h-full flex flex-col items-center justify-center'
              t={t}
            />
            <div className="flex flex-col w-full lg:w-1/3 items-start justify-center">
              <div className="flex items-center mt-4 lg:mt-0">
                <input type="checkbox" id="remove-background" className="mr-2" />
                <label htmlFor="remove-background" className="text-gray-500 text-sm font-manrope">
                  {t('Remove background behind logo')}
                </label>
              </div>
              <p className="text-gray-500 w-auto mt-2 text-xs border border-2 rounded p-2 border-logoLightPurple text-smallTextGray font-manrope">
                {t('Upload your own custom logo image as .png, .jpg, .gif or .svg file format with a maximum size of')}
                <span className="font-bold"> 2 MB</span>. {t('You can also select a logo for your QR code from the gallery.')}
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DesignPanel;
