import React from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import StripeButton from '../StripeButton/StripeButton.jsx';


const DonatePage = () => {
  return (
    <div className="flex justify-center lg:w-4/6 lg:mx-auto items-center m-3 pb-2">
       <Helmet>
        <title>Donate | Support QR-Quick's Mission | QR-Quick</title>
        <meta
          name="description"
          content="Support QR-Quick by making a donation. Your contribution helps us maintain and improve our free QR code generator, ensuring accessibility for everyone."
        />
        <meta property="og:title" content="Donate | Support QR-Quick's Mission | QR-Quick" />
        <meta
          property="og:description"
          content="Support QR-Quick by making a donation. Your contribution helps us maintain and improve our free QR code generator, ensuring accessibility for everyone."
        />
        <meta property="og:url" content="https://qr-quick.site/donate" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://qr-quick.site/static/media/QRQuickLogo.17cb7f82756bcd9fe968.png" />     
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Donate | Support QR-Quick's Mission | QR-Quick" />
        <meta
          name="twitter:description"
          content="Support QR-Quick by making a donation. Your contribution helps us maintain and improve our free QR code generator, ensuring accessibility for everyone."
        />
        <meta name="twitter:image" content="https://qr-quick.site/static/media/QRQuickLogo.17cb7f82756bcd9fe968.png" /> 
      </Helmet>
      <div className="flex flex-col w-full items-center p-8 mt-6 text-center bg-white border border-gray-300 rounded-lg shadow-lg md:mt-8">
        <h1 className="text-3xl font-bold mb-8 text-boldTextGray font-manrope">Donate Us</h1>
        <p className="w-3/4 text-xl mb-12 font-manrope text-smallTextGray">
          Your donations help us maintain and refine the service!
        </p>
        <StripeButton/>
      </div>
    </div>
  );
};

export default DonatePage;

