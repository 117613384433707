import React from 'react';

import { NavLink } from 'react-router-dom';


function Footer() {
  return (
    <footer className='pb-3'>
       <div
          aria-label="Global"
          className="m-3 height-full lg:mx-44 backdrop-blur-lg shadow-lg flex lg:w-4/6 lg:mx-auto items-center justify-between p-3 my-1 lg:py-3 lg:px-2 rounded-lg text-navButtonsGray font-manrope"
        >
          <NavLink>Privacy Policy</NavLink>
          <NavLink>QR Quick - All rights reserved © 2024</NavLink>
          <NavLink>Terms of Use</NavLink>
        </div>
    </footer>
  );
}

export default Footer;