import React from "react";

function MainText() {
  return (
    <div className="p-6 flex flex-col  font-manrope rounded-lg lg:mt-8 max-w-3xl col-span-2 backdrop-blur-xl border shadow-xl mb-auto rounded-lg text-navButtonsGray ">
      <h1 className="font-manrope mx-auto">Design and Download Professional QR Codes Quickly!</h1>
    </div>
  );
}

export default MainText;