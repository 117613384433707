import React from 'react';
import QRCodeStyling from 'qr-code-styling';
import PropTypes from 'prop-types';

const ExamplePatterns = ({
  setMainColor,
  setBgColor,
  setSelectedOverallStyle,
  setSelectedEyeFrameStyle,
  setSelectedEyeBallStyle,
  setActivePatternId,     
  activePatternId,         
}) => {
  const patterns = [
    {
      id: 1,
      mainColor: '#000000',
      bgColor: '#ffffff',
      selectedOverallStyle: 'square',
      selectedEyeFrameStyle: 'square',
      selectedEyeBallStyle: 'square',
    },
    {
      id: 2,
      mainColor: '#FF0000',
      bgColor: '#ffffff',
      selectedOverallStyle: 'dots',
      selectedEyeFrameStyle: 'extra-rounded',
      selectedEyeBallStyle: 'dot',
    },
    {
      id: 3,
      mainColor: '#0000FF',
      bgColor: '#FFFF00',
      selectedOverallStyle: 'square',
      selectedEyeFrameStyle: 'rounded',
      selectedEyeBallStyle: 'square',
    },
    {
      id: 4,
      mainColor: '#00FF00',
      bgColor: '#000000',
      selectedOverallStyle: 'dots',
      selectedEyeFrameStyle: 'dot',
      selectedEyeBallStyle: 'dot',
    },
  ];


  const applyPattern = (pattern) => {
    setMainColor(pattern.mainColor);
    setBgColor(pattern.bgColor);
    setSelectedOverallStyle(pattern.selectedOverallStyle);
    setSelectedEyeFrameStyle(pattern.selectedEyeFrameStyle);
    setSelectedEyeBallStyle(pattern.selectedEyeBallStyle);
    setActivePatternId(pattern.id); 
    // setCustomCornerDotPath(pattern.customCornerDotPath);
  };

  return (
    <div className="bg-white col-span-2 mx-auto p-3 font-manrope w-full h-auto rounded-lg">
      <div className="flex flex-row gap-x-2 font-manrope text-boldTextGray font-semibold p-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M5.99922 6H2.25" stroke="#D1D2E3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6 2.25V18.0001H21.75" stroke="#D1D2E3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.0003 15.0001V6H8.99951" stroke="#D1D2E3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18 21.75V18" stroke="#D1D2E3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <rect x="9" y="9" width="6" height="6" rx="1" fill="#958AF0" />
        </svg>
        Choose Ready Pattern
      </div>
      <div className="flex lg:flex-row flex-col gap-3">
        {patterns.map((pattern) => (
          <div
            key={pattern.id}
            className={`w-min lg:mx-auto h-auto cursor-pointer border-2 flex  ${
              activePatternId === pattern.id ? 'border-activeButtonPurple' : 'border-logoLightPurple'
            } rounded-lg p-1 hover:border-purple-300 transition-colors duration-300`}
            onClick={() => applyPattern(pattern)}
          >
            <PatternQRCode pattern={pattern} />
          </div>
        ))}
      </div>
    </div>
  );
};

ExamplePatterns.propTypes = {
  setMainColor: PropTypes.func.isRequired,
  setBgColor: PropTypes.func.isRequired,
  setSelectedOverallStyle: PropTypes.func.isRequired,
  setSelectedEyeFrameStyle: PropTypes.func.isRequired,
  setSelectedEyeBallStyle: PropTypes.func.isRequired,
  setCustomCornerDotPath: PropTypes.func.isRequired,
  setActivePatternId: PropTypes.func.isRequired,
  activePatternId: PropTypes.number,
};

function PatternQRCode({ pattern }) {
  const qrCodeRef = React.useRef(null);

  React.useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 120,
      height: 120,
      data: 'https://qr-quick.site/',
      image: undefined, 
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 5,
        height: 50,
        width: 50,
      },
      dotsOptions: {
        color: pattern.mainColor,
        type: pattern.selectedOverallStyle,
      },
      cornersSquareOptions: {
        color: pattern.mainColor,
        type: pattern.selectedEyeFrameStyle,
      },
      cornersDotOptions: {
        color: pattern.mainColor,
        type: pattern.selectedEyeBallStyle,
        customCornersDot:
          pattern.selectedEyeBallStyle === 'custom'
            ? pattern.customCornerDotPath
            : undefined,
      },
      backgroundOptions: {
        color: pattern.bgColor,
      },
      qrOptions: {
        errorCorrectionLevel: 'H',
      },
    });

    qrCode.append(qrCodeRef.current);

    // Cleanup to prevent stacking QR codes
    return () => {
      if (qrCodeRef.current) {
        qrCodeRef.current.innerHTML = '';
      }
    };
  }, [pattern]);

  return <div ref={qrCodeRef} />;
}

PatternQRCode.propTypes = {
  pattern: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mainColor: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    selectedOverallStyle: PropTypes.string.isRequired,
    selectedEyeFrameStyle: PropTypes.string.isRequired,
    selectedEyeBallStyle: PropTypes.string.isRequired,
    customCornerDotPath: PropTypes.string,
  }).isRequired,
};

export default ExamplePatterns;
