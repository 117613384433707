import React, { useEffect, useRef } from 'react';
import iro from '@jaames/iro';

const ColorPicker = ({ onColorChange }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.innerHTML = '';

    const colorPicker = new iro.ColorPicker(containerRef.current, {
      width: 120,
      color: '#f00',
    });

    colorPicker.on('color:change', (color) => {
      onColorChange(color.hexString);
    });

    return () => {
      // Clean up event listener when the component unmounts
      colorPicker.off('color:change');
    };
  }, [onColorChange]);

  return <div className="w-full" ref={containerRef}></div>;
};

export default ColorPicker;
