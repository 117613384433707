import React, { useState } from 'react';
import UrlInput from '../UrlInput/UrlInput';
import QRCode from '../QRCode/QRCode';
import DesignPanel from '../DesignPanel/DesignPanel';
import ExamplePatterns from '../ExamplePatterns/ExamplePatterns';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import MainText from '../MainText/MainText';

const MainComponent = () => {
  const [url, setUrl] = useState('');
  const [mainColor, setMainColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [logoFile, setLogoFile] = useState(null);
  const [selectedOverallStyle, setSelectedOverallStyle] = useState('square');
  const [selectedEyeFrameStyle, setSelectedEyeFrameStyle] = useState('square');
  const [selectedEyeBallStyle, setSelectedEyeBallStyle] = useState('square');
  const [customCornerDotPath, setCustomCornerDotPath] = useState('');
  const [activePatternId, setActivePatternId] = useState(null);

  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Main Page' });

  return (
    <div>
      <Helmet>
        <title>QR-Quick: Free QR Code Generator | Customize & Download</title>
                <meta
          name="description"
          content="Generate customizable QR codes for free without registration. Change colors, shapes, and download in SVG, PNG, JPG formats effortlessly."
        />        
        <meta property="og:title" content="QR-Quick: Free QR Code Generator | Customize & Download" />
        <meta
          property="og:description"
          content="Generate customizable QR codes for free without registration. Change colors, shapes, and download in SVG, PNG, JPG formats effortlessly."
        />
        <meta property="og:url" content="https://qr-quick.site/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://qr-quick.site/static/media/QRQuickLogo.17cb7f82756bcd9fe968.png" />     
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="QR-Quick: Free QR Code Generator | Customize & Download" />
        <meta
          name="twitter:description"
          content="Generate customizable QR codes for free without registration. Change colors, shapes, and download in SVG, PNG, JPG formats effortlessly."
        />
        <meta name="twitter:image" content="https://qr-quick.site/static/media/QRQuickLogo.17cb7f82756bcd9fe968.png" /> 
      </Helmet>

      {/* Desktop Layout */}
      <div className='hidden lg:block box-border lg:grid grid-cols-3 lg:w-4/6 m-3 lg:mx-auto gap-3 auto-rows-auto'>
        <MainText />
        <UrlInput url={url} setUrl={setUrl} />
        <QRCode
          url={url}
          mainColor={mainColor}
          bgColor={bgColor}
          logoImage={logoFile}
          selectedOverallStyle={selectedOverallStyle}
          selectedEyeFrameStyle={selectedEyeFrameStyle}
          selectedEyeBallStyle={selectedEyeBallStyle}
          customCornerDotPath={customCornerDotPath}
        />
        <DesignPanel
          mainColor={mainColor}
          setMainColor={setMainColor}
          bgColor={bgColor}
          setBgColor={setBgColor}
          logoFile={logoFile}
          setLogoFile={setLogoFile}
          selectedOverallStyle={selectedOverallStyle}
          setSelectedOverallStyle={setSelectedOverallStyle}
          selectedEyeFrameStyle={selectedEyeFrameStyle}
          setSelectedEyeFrameStyle={setSelectedEyeFrameStyle}
          selectedEyeBallStyle={selectedEyeBallStyle}
          setSelectedEyeBallStyle={setSelectedEyeBallStyle}
          customCornerDotPath={customCornerDotPath}
          setCustomCornerDotPath={setCustomCornerDotPath}
        />
        <ExamplePatterns
          setMainColor={setMainColor}
          setBgColor={setBgColor}
          setSelectedOverallStyle={setSelectedOverallStyle}
          setSelectedEyeFrameStyle={setSelectedEyeFrameStyle}
          setSelectedEyeBallStyle={setSelectedEyeBallStyle}
          setCustomCornerDotPath={setCustomCornerDotPath}
          setActivePatternId={setActivePatternId}
          activePatternId={activePatternId}       
        />
      </div>
      {/* Mobile Layout */}
      <div className='lg:hidden flex flex-col box-border gap-3 m-3'>
        <MainText />
        <QRCode
          url={url}
          mainColor={mainColor}
          bgColor={bgColor}
          logoImage={logoFile}
          selectedOverallStyle={selectedOverallStyle}
          selectedEyeFrameStyle={selectedEyeFrameStyle}
          selectedEyeBallStyle={selectedEyeBallStyle}
          customCornerDotPath={customCornerDotPath}
        />
        <UrlInput url={url} setUrl={setUrl} />
        <DesignPanel
          mainColor={mainColor}
          setMainColor={setMainColor}
          bgColor={bgColor}
          setBgColor={setBgColor}
          logoFile={logoFile}
          setLogoFile={setLogoFile}
          selectedOverallStyle={selectedOverallStyle}
          setSelectedOverallStyle={setSelectedOverallStyle}
          selectedEyeFrameStyle={selectedEyeFrameStyle}
          setSelectedEyeFrameStyle={setSelectedEyeFrameStyle}
          selectedEyeBallStyle={selectedEyeBallStyle}
          setSelectedEyeBallStyle={setSelectedEyeBallStyle}
          customCornerDotPath={customCornerDotPath}
          setCustomCornerDotPath={setCustomCornerDotPath}
        />
        <ExamplePatterns
          setMainColor={setMainColor}
          setBgColor={setBgColor}
          setSelectedOverallStyle={setSelectedOverallStyle}
          setSelectedEyeFrameStyle={setSelectedEyeFrameStyle}
          setSelectedEyeBallStyle={setSelectedEyeBallStyle}
          setCustomCornerDotPath={setCustomCornerDotPath}
          setActivePatternId={setActivePatternId} 
          activePatternId={activePatternId}       
        />
      </div>
    </div>
  );
};

export default MainComponent;
