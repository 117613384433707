import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./common/Header/Main/Main";
import Footer from "./common/Footer/Main/Main";
import Main from "./pages/Main/Main/Main";
import DonatePage from "./pages/DonatePage/Main/DonatePage";
import SupportPage from "./pages/Support/Main/Support";

function App() {
  return (
    <div className="min-h-screen bg-custom-bg bg-cover bg-center bg-no-repeat">
      <Helmet>
        {/* Default meta tags */}
        <title>QR-Quick QR Code Generator - Create and Download QR Codes</title>
        <meta
          name="description"
          content="Generate customizable QR codes for free without registration. Change colors, shapes, and download in various formats."
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="QR-Quick QR Code Generator - Create and Download QR Codes"
        />
        <meta
          name="keywords"
          content="QR, QR code,QRcode, QRCODE, QR Quick, qr quick, quick, without registration, generator, free, customizable, download, png, jpeg, svg"
        />
        <meta
          property="og:description"
          content="Generate customizable QR codes for free without registration. Change colors, shapes, and download in various formats."
        />
        <meta property="og:url" content="https://qr-quick.site" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/donate" element={<DonatePage />} />
          <Route path="/support" element={<SupportPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
