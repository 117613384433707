import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { User, Mail, PenTool } from 'lucide-react';
import ScreenshotUploader from '../../../common/ScreenshotUploader/ScreenshotUploader';
import { Helmet } from 'react-helmet';


export default function FeedbackForm() {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const screenshotUploaderRef = useRef(null);

  const handleRemoveLogo = () => {
    if (screenshotUploaderRef.current) {
      screenshotUploaderRef.current.removeScreenshot();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPercentage(0);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('message', message);
    if (screenshot) {
      formData.append('screenshot', screenshot);
    }

    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 100);

    try {
      const response = await fetch(`/send-report`, {
        method: 'POST',
        body: formData,
      });

      const responseData = await response.text();
      setResponseMessage(responseData);

      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setScreenshot(null);

      handleRemoveLogo();

      ReactGA.event({ category: 'Support', action: 'Support report Submitted' });
    } catch (error) {
      setResponseMessage('An error occurred while sending the report.');
    }

    setShowResponseMessage(true);
    setTimeout(() => {
      setShowResponseMessage(false);
    }, 3000);

    setLoading(false);
    setPercentage(0);
  };

  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: 'Support Page',
  });

  return (
    <div className="font-manrope flex items-center justify-center p-4">
      <Helmet>
        <title>Support | QR-Quick: Get Help & Provide Feedback</title>
        <meta
          name="description"
          content="Need assistance or want to provide feedback? Reach out to QR-Quick's support team for help with generating your QR codes or to share your suggestions."
        />
        <meta property="og:title" content="Support | QR-Quick: Get Help & Provide Feedback" />
        <meta
          property="og:description"
          content="Need assistance or want to provide feedback? Reach out to QR-Quick's support team for help with generating your QR codes or to share your suggestions."
        />
        <meta property="og:url" content="https://qr-quick.site/support" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://qr-quick.site/static/media/QRQuickLogo.17cb7f82756bcd9fe968.png" />             
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Support | QR-Quick: Get Help & Provide Feedback" />
        <meta
          name="twitter:description"
          content="Need assistance or want to provide feedback? Reach out to QR-Quick's support team for help with generating your QR codes or to share your suggestions."
        />
        <meta name="twitter:image" content="https://qr-quick.site/static/media/QRQuickLogo.17cb7f82756bcd9fe968.png" /> 
      </Helmet>
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg">
        <div className="p-6">
          <h1 className="text-2xl font-semibold text-center text-gray-700">
            {t('Feedback Form')}
          </h1>
          <p className="text-center text-sm text-gray-500">
            {t('Fill in to help us fix issues!')}
          </p>
          <form onSubmit={handleSubmit} className="space-y-4 mt-4">
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-medium text-gray-700">
                {t('Name')} *
              </label>
              <div className="relative">
                <User
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={18}
                />
                <input
                  id="name"
                  type="text"
                  placeholder="John Doe"
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    ReactGA.event({ category: 'Support', action: 'Support report started' });
                  }}
                  required
                />
              </div>
            </div>
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-gray-700">
                {t('Email')}
              </label>
              <div className="relative">
                <Mail
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={18}
                />
                <input
                  id="email"
                  type="email"
                  placeholder="example@email.com"
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="space-y-2">
              <label htmlFor="subject" className="text-sm font-medium text-gray-700">
                {t('Subject')}
              </label>
              <input
                id="subject"
                type="text"
                placeholder={t('What is your request about?')}
                className="w-full pl-3 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="message" className="text-sm font-medium text-gray-700">
                {t('Message')} *
              </label>
              <div className="relative">
                <PenTool className="absolute left-3 top-3 text-gray-400" size={18} />
                <textarea
                  id="message"
                  placeholder={t('Please describe your issue or request here.')}
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="screenshot" className="text-sm font-medium text-gray-700">
                {t('Screenshot (if needed)')}{' '}
                <span className="text-xs text-gray-500">(Max 2 MB)</span>
              </label>
              <ScreenshotUploader
                onFileSelect={setScreenshot}
                ref={screenshotUploaderRef}
                t={t}
                className='pt-2'
              />
            </div>
            {showResponseMessage ? (
              <p className="text-lg text-green-500">{responseMessage}</p>
            ) : (
              <button
                type="submit"
                disabled={loading}
                className={`w-full py-3 mt-4 text-white rounded-md transition-all duration-300 ease-in-out ${
                  loading
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-gradient-to-r from-purple-400 via-blue-400 to-green-400 hover:scale-105'
                }`}
              >
                {loading ? (
                  <div className="flex justify-center items-center">
                    <div className="w-10 h-10 border-2 border-gray-400 rounded-full relative">
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs">
                        {percentage}%
                      </div>
                    </div>
                  </div>
                ) : (
                  t('Send Feedback')
                )}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
