import React from 'react';

function UrlInput({ url, setUrl }) {
  return (
    <div className="p-6 flex bg-white flex-col 	justify-items: center items-start font-manrope rounded-lg max-w-3xl col-span-2 mb-auto ">
      <label htmlFor="url" className='pb-3 text-boldTextGray font-semibold'>Enter URL:</label>
      <div className='flex rounded-md rounded-lg border-none bg-navLangOptionsPurple items-center w-full'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='w-auto h-6 pl-3'>
        <path d="M9 6.75V4.5" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 19.5V17.25" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.75 9H4.5" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.5 15H17.25" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.6592 12.0376L5.59854 13.0983C4.89562 13.8016 4.50083 14.7553 4.50098 15.7497C4.50112 16.744 4.8962 17.6976 5.59933 18.4008C6.30245 19.1039 7.25605 19.499 8.25042 19.4991C9.24479 19.4993 10.1985 19.1045 10.9018 18.4016L11.9625 17.3409" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.3409 11.9625L18.4016 10.9018C19.1045 10.1985 19.4993 9.24479 19.4991 8.25042C19.499 7.25605 19.1039 6.30245 18.4008 5.59933C17.6976 4.8962 16.744 4.50112 15.7497 4.50098C14.7553 4.50083 13.8016 4.89562 13.0983 5.59854L12.0376 6.6592" stroke="#958AF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
        <input
            type="text"
            id="url"
            placeholder="https://qr-quick.site/"
            className="bg-transparent w-full p-2"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
        />
      </div>
    </div>
  );
}

export default UrlInput;
